<template>
  <SearchTrainUI @chooseTicket="chooseTicket"/>
</template>

<script>
import SearchTrainUI from "./components/SearchTrainUI";
export default {
  name: "BuyTrain",
  components: { SearchTrainUI },
  methods: {
    // 选票
    chooseTicket() {
      this.$router.push("/orderTrain")
    },
  },
};
</script>
